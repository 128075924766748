import Link from 'next/link'
import { getAppData } from '../lib/api/common'

import Layout from "../components/layout/Layout"
import PageHeader from '../components/content/page/PageHeader'

import {
	revalidateSeconds
} from "../lib/helpers/revalidateSeconds";


export type Custom404Props = {
	app: any
}

export async function getStaticProps(context: any) {

	const app: any = await getAppData();

	return {
		props: {
			app: app ? app : null
		},
		revalidate: revalidateSeconds()
	}
}

export default function Custom404(props: Custom404Props) {

	const { app } = props

	return (
		<>
			<Layout appData={app}>
				<>
					<section className={`page-not-found`}>
						<header className="content-container__header-wrapper">
							<div className="content-container__header-title">
								<h1>Sorry, the page you are looking for was not found.</h1>
								<p><Link href={`/`} className="home-link">Return home</Link>, or see if you can find what you were looking for in footer below.</p>
							</div>
							
						</header>
					</section>
				</>
			</Layout>
		</>
	)
}

